import React, { useContext } from "react";
import PokerTable from "./components/pokerTable";
import Tables from "./components/tables";

import { PokerContext, PokerProvider } from "./PokerContext"; // ✅ Correct import

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif", // Specify your custom font stack with Poppins
  },
});

const App = () => {
  return (
    <PokerProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* Ensures correct styling */}
        <MainComponent /> {/* Move useContext here */}
      </ThemeProvider>
    </PokerProvider>
  );
};

// Separate component so useContext is inside PokerProvider
const MainComponent = () => {
  const { tableId } = useContext(PokerContext); // ✅ Now this works

  return tableId === "" ? <Tables /> : <PokerTable tableId={tableId} />;
};

export default App;
