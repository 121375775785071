import React, { createContext, useState } from "react";

// Create the Context
export const PokerContext = createContext();

// Create the Provider Component
export const PokerProvider = ({ children }) => {
  const [tableId, setTableId] = React.useState("");

  return (
    <PokerContext.Provider value={{ tableId, setTableId }}>
      {children}
    </PokerContext.Provider>
  );
};
