import React, { useState, useContext, useEffect } from "react";
import { PokerContext } from "../PokerContext";

import axiosConfig from "../axiosConfig";

/* MATERIAL UI */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import useStyles from "../useStyles"; // Update with the correct path
const {
  formatDollarAmount,
  getImageUrl,
  rotateLeftByN,
  findPlayerIndexById,
  findWinnerById,
  doesPlayerExist,
  doesCardExist,
  adjustFoldedPlayerLocation,
  areArraysEqual,
} = require("../functions/common");

const Tables = () => {
  const classes = useStyles();

  const { tableId, setTableId } = useContext(PokerContext);
  const [tables, setTables] = useState([]);

  useEffect(() => {
    const getTables = async () => {
      try {
        const response = await axiosConfig.get("/tables");
        console.log(response.data);
        console.log(response.data.games);
        setTables(response.data.games); // Store data in state if needed
      } catch (error) {
        console.error(error.response?.data || "Error fetching tables");
      }
    };

    getTables();
  }, []);

  useEffect(() => {
    console.log("tables: ", tables);
  }, [tables]);

  return (
    <>
      <Grid
        container
        sx={{
          //backgroundColor: "red",
          display: "flex",
          marginTop: "20px",
          marginBottom: "20px",
        }}
        spacing={2}
      >
        {tables.map((table, i) => (
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              //backgroundColor: "yellow",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            onClick={() => setTableId(table.tableId)}
          >
            {/* Table boxes */}
            <Box
              className={classes.wallpaper}
              sx={{
                backgroundColor: "black",
                display: "flex",
                flexDirection: "column",
                width: "50%",
                color: "white",
                height: "20vh!important",
                borderRadius: "15px",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: "3vh",
                  textAlign: "center",
                }}
              >
                STAKES
              </Typography>

              <Typography
                variant="body1"
                style={{
                  fontSize: "3vh",
                  textAlign: "center",
                }}
              >
                {`${formatDollarAmount(table.smallBlind)}/${formatDollarAmount(
                  table.bigBlind
                )}`}
              </Typography>

              <Typography
                variant="body1"
                style={{
                  fontSize: "3vh",
                  textAlign: "center",
                }}
              >
                BUY IN:{" "}
                {`${formatDollarAmount(table.minBuy)}-${formatDollarAmount(
                  table.maxBuy
                )}`}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Tables;
