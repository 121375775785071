import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@mui/material";

const { formatDollarAmount } = require("../../functions/common");

const PlayerActionUI = ({ classes, i, player, game }) => {
  function retrieveColor(player) {
    let Color;

    switch (player.action) {
      case "":
        Color = "#7f8c8d";
        break;
      case "check":
        Color = "#3498db";
        break;
      case "call":
        Color = "#e67e22";
        break;
      case "bet":
        Color = "#9b59b6";
        break;
      case "fold":
        Color = "#e74c3c";
        break;
      case "raise":
        Color = "#f1c40f";
        break;
      case "all-in":
        Color = "#3498db";
        break;
    }

    return Color;
  }

  function retrieveLetter(player, game) {
    if (player?.action === "" && player?.currentBet === game?.bigBlind) {
      return "B";
    }

    if (player?.action === "" && player?.currentBet === game?.smallBlind) {
      return "S";
    }

    if (player?.action === "fold") {
      return "F";
    }

    if (player?.action === "check") {
      return "C";
    }

    if (player?.action === "call") {
      return "C";
    }

    if (player?.action === "bet") {
      return "B";
    }

    if (player?.action === "raise") {
      return "R";
    }

    if (player?.action === "all-in") {
      return "A";
    }
  }

  function retrieveAmount(player) {
    if (player?.action === "fold") {
      return "Fold";
    } else if (player?.action === "check") {
      return "Check";
    } else {
      return formatDollarAmount(player.currentBet);
    }
  }

  return (
    (player?.action !== "" || player?.currentBet !== 0) && (
      <Box
        className={`${classes.playerActionContainer} ${
          classes[`playerAction${i + 1}`]
        }`}
        sx={{
          border: `2px solid ${retrieveColor(player)}`,
        }}
      >
        <Box
          className={classes.playerCallIcon}
          style={{ backgroundColor: `${retrieveColor(player)}` }}
        >
          <Typography variant="body1" style={{ fontSize: "2vh" }}>
            {retrieveLetter(player, game)}
          </Typography>
        </Box>
        <Box className={classes.playerAmountLabel}>
          <Typography variant="body1" style={{ fontSize: "2vh" }}>
            {retrieveAmount(player)}
          </Typography>
        </Box>
      </Box>
    )
  );
};

export default PlayerActionUI;
